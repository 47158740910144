import { SyntheticEvent, useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import styles from "./App.module.css";

interface Post {
  title: string;
  username: string;
  content: string;
  date: string;
  vote: number;
}

function App() {
  const [posts, setPosts] = useState<Post[]>([]);
  const [addingPost, setAddingPost] = useState(false);
  const [newPostTitle, setNewPostTitle] = useState("");
  const [newPostUsername, setNewPostUsername] = useState("");
  const [newPostContent, setNewPostContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await fetch("https://api_worker.kenthansen98.workers.dev/posts");
      const json = await res.json();
      setPosts(json);
    };

    fetchPosts();
  }, []);

  const parseDate = (date: string) => {
    const postDate = dayjs(date);
    dayjs.extend(relativeTime);

    return postDate.fromNow();
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (newPostTitle === "" || newPostUsername === "" || newPostContent === "") {
      setErrorMessage("Please fill in all fields.");
      return;
    } else {
      setErrorMessage("");
    }
    const newPost: Post = {
      title: newPostTitle,
      username: newPostUsername, 
      content: newPostContent,
      date: dayjs().toString(),
      vote: 0,
    };
    const res = await fetch("https://api_worker.kenthansen98.workers.dev/posts", {
      method: "POST", 
      body: JSON.stringify(newPost)
    });
    const json = await res.json();
    setPosts(json);
    setAddingPost(false);
    setNewPostTitle("");
    setNewPostUsername("");
    setNewPostContent("");
  };

  const modifyVote = (delta: number, postTitle: string) => {
    setPosts(posts.map((post) => post.title === postTitle ? { ...post, vote: post.vote += delta } : post));
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>CloudSocial</h1>
      <div className={styles.add} onClick={() => { 
          setAddingPost(!addingPost); 
          setErrorMessage("");
        }
      }>
        +
      </div>
      {addingPost && (
        <div className={styles.post}>
          <form className={styles.form} onSubmit={onSubmit}>
            <input className={styles.input} placeholder="Title" value={newPostTitle} onChange={(e) => setNewPostTitle(e.target.value)} /><br/>
            <input className={styles.input} placeholder="Username" value={newPostUsername} onChange={(e) => setNewPostUsername(e.target.value)} /><br/>
            <textarea className={styles.textarea} placeholder="Post" value={newPostContent} onChange={(e) => setNewPostContent(e.target.value)} /><br/>
            {errorMessage && <span className={styles.error}>{errorMessage}</span>}
            <button>+ Create</button>
          </form>
        </div>
      )}
      {posts.sort((a, b) => b.vote - a.vote).map((post, i) => (
        <div key={i} className={styles.post}>
          <div className={styles.header}>
            <h3>{post.title}</h3>
            <i>{post.username}</i>
          </div>
          <hr/>
          <p>{post.content}</p>
          <div className={styles.votes}>
            <div className={styles.arrow} onClick={() => modifyVote(1, post.title)}>&#9650;</div> 
            {post.vote}
            <div className={styles.arrow} onClick={() => modifyVote(-1, post.title)}>&#x25BC;</div> 
          </div>
          <i className={styles.date}>{parseDate(post.date)}</i>
        </div>
      ))}
    </div>
  );
}

export default App;
